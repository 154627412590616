<template>
  <v-card class="pa-4" flat>
    <div class="d-flex align-center flex-wrap ">
      <FilterSchoolYear
        :dataFilter="dataFilter"
        notMultiple
        @handler="getFilter"
      />
      <div class="font-weight-bold ml-2">
        {{ table.dataTable.total + " " + $t("app.student") }}
      </div>

      <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

      <v-btn
        class="gradient-primary ma-2"
        dark
        depressed
        @click="
          $router.push({
            name: 'BkPoints',
            query: {
              year: idSchoolYear,
              grade: idGrade || $route.query.grade,
              class: idClass
            }
          })
        "
      >
        {{ $t("bk.add_category") }}
        <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
      </v-btn>
    </div>

    <v-divider class="mb-4 mt-3" />

    <v-data-table
      :headers="tableHeaders"
      :items="table.dataTable.data"
      :page.sync="table.page"
      :items-per-page="table.limit"
      :loading="table.loading"
      height="650"
      fixed-header
      hide-default-footer
      disable-pagination
      class="elevation-0"
    >
      <template #item.name="{ item }">
        <div class="d-flex align-center">
          <v-avatar class="ma-2">
            <v-img :src="getPhoto(item)" />
          </v-avatar>
          <div>
            <div>
              {{ item.name }}
            </div>
            <div class="caption">
              {{ item.nis }}
            </div>
          </div>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                v-on="on"
                icon
                small
                dark
                class="primary mr-2"
                @click="viewBkNotes(item.person)"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("bk.detail_counseling_guidance") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                v-on="on"
                icon
                small
                dark
                class="primary"
                @click="showModal(item.person)"
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("bk.add_counseling_guidance") }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <Pagination
      :length="table.dataTable.last_page"
      :total="table.dataTable.total"
      :current-page="table.page"
      :limit="table.limit"
      :handler="paginateChange"
    />

    <v-dialog v-model="modaladdnotes" scrollable width="500" persistent>
      <v-card>
        <v-card-title class="title" primary-title>
          {{ $t("bk.add_counseling_guidance") }}
          <v-spacer />
          <v-btn :disabled="form_notes.loading" small text @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form ref="form_bknotes" v-model="bkNotes" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-radio-group
                  v-model="modelType"
                  row
                  @change="listMasterConseling"
                >
                  <v-radio :label="$t('bk.award')" value="award" />
                  <v-radio :label="$t('bk.violant')" value="foul" />
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6">
                <DatePicker
                  :label="$t('bk.date')"
                  :value="form_notes.date"
                  notitle
                  outlined
                  dense
                  @getValue="v => (form_notes.date = v)"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="form_notes.conseling"
                  :items="masterConseling"
                  :label="$t('bk.category')"
                  :rules="[v => !!v || $t('app.required')]"
                  :loading="form_notes.loading"
                  :disabled="form_notes.loading"
                  item-value="id"
                  item-text="description"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="modelImage"
                  label="Browse"
                  outlined
                  dense
                  show-size
                  chips
                  clearable
                  accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">{{ text }}</v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form_notes.description"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('bk.notes')"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form_notes.action"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('app.action')"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :loading="form_notes.loadingUnpublish"
            :disabled="form_notes.loadingPublish"
            color="primary"
            outlined
            depressed
            @click="unPublish"
          >
            {{ $t("bk.save_draft") }}
          </v-btn>
          <v-btn
            :loading="form_notes.loadingPublish"
            :disabled="form_notes.loadingUnpublish"
            class="gradient-primary white--text"
            depressed
            @click="publish"
          >
            {{ $t("bk.publish") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  getConselingList,
  getMasterConseling,
  addConseling
} from "@/api/admin/academic/conseling";
import i18n from "@/i18n";
import DatePicker from "@/components/DatePicker";
import Pagination from "@/components/Pagination";
import { uploadFile } from "@/api/admin/admin";
import FilterSchoolYear from "../../components/filterSchooYear";
import { isExternal } from "@/utils/validate";

export default {
  metaInfo: {
    title: i18n.t("routes.bk"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Pagination,
    DatePicker,
    FilterSchoolYear
  },
  mounted() {
    this.dataFilter = this.$route.query;
  },
  data() {
    return {
      listGrade: [],
      idSchoolYear: null,
      idGrade: null,
      idClass: null,
      loadingTable: false,
      bkNotes: false,
      tableHeaders: [
        {
          text: i18n.t("bk.table.name"),
          value: "name"
        },
        {
          text: i18n.t("bk.table.award"),
          value: "award"
        },
        {
          text: i18n.t("bk.table.award_points"),
          value: "award_point"
        },
        {
          text: i18n.t("bk.table.violation"),
          value: "foul"
        },
        {
          text: i18n.t("bk.table.violation_points"),
          value: "foul_point"
        },
        {
          text: i18n.t("app.action"),
          value: "action",
          sortable: false,
          width: 100
        }
      ],
      dataFilter: { year: null, grade: null, class: null },
      table: {
        dataTable: { data: [], total: 0 },
        loading: false,
        search: "",
        page: 1,
        limit: 10
      },
      modaladdnotes: false,
      masterConseling: [],
      modelType: "",
      modelImage: null,
      form_notes: {
        loading: false,
        loadingPublish: false,
        loadingUnpublish: false,
        person: 0,
        conseling: 0,
        date: "",
        file: "",
        description: "",
        status: "",
        action: ""
      },
      boyPhoto: require("@/assets/svg/boy.svg"),
      girlPhoto: require("@/assets/svg/girl.svg")
    };
  },
  methods: {
    getPhoto(item) {
      let photo = "";
      if (isExternal(item.photo)) {
        photo = item.photo;
      } else {
        switch (item.gender) {
          case "L":
            photo = this.boyPhoto;
            break;
          case "P":
            photo = this.girlPhoto;
            break;
          default:
            photo = this.boyPhoto;
            break;
        }
      }
      return photo;
    },
    getFilter(res) {
      if (res.type === "school_year") {
        this.idSchoolYear = res.filter.school_year;
      }
      if (res.type === "select_grade") {
        this.idGrade = res.filter.grade;
      }
      if (res.type === "select_class" && typeof res.filter.class !== "object") {
        this.idClass = res.filter.class;
        this.getListConseling();
      }
    },
    async uploadImage() {
      let result = "";
      if (this.modelImage) {
        try {
          let bodyImage = new FormData();
          bodyImage.set("path", "counseling");
          bodyImage.set("module", "COUNSELING");
          bodyImage.append("file", this.modelImage);
          const response = await uploadFile(bodyImage);
          if (response.data.code) {
            result = response.data.data.path;
          } else {
            this.snackBar(true, response.data.message);
          }
        } catch (error) {
          this.snackBar(true, error);
        }
      }
      return result;
    },
    async saveConseling() {
      this.form_notes.loading = true;
      try {
        const pathImage = await this.uploadImage();
        this.form_notes.file = pathImage;
        const response = await addConseling(this.form_notes);
        if (response.data.code) {
          const d = response.data.data;
          this.$socket.emit("serverNotif", {
            recipient: d.recipient,
            data: d
          });
          this.snackBar(false, this.$i18n.t("bk.msg.added_conseling"));
          this.viewBkNotes(this.form_notes.person);
          this.closeDialog();
          this.getListConseling();
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.snackBar(true, error);
      }
      this.form_notes.loading = false;
      this.form_notes.loadingPublish = false;
      this.form_notes.loadingUnpublish = false;
    },
    showModal(person) {
      this.modelType = "award";
      this.modaladdnotes = true;
      this.form_notes.person = person;
      this.listMasterConseling();
    },
    async listMasterConseling() {
      this.form_notes.loading = true;
      try {
        const response = await getMasterConseling({
          type: this.modelType
        });
        if (response.data.code) {
          this.masterConseling = response.data.data.data;
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.snackBar(true, error);
      }
      this.form_notes.loading = false;
    },
    async getListConseling() {
      this.table.loading = true;
      try {
        const response = await getConselingList({
          class: this.idClass,
          limit: this.table.limit,
          page: this.table.page
        });
        if (response.data.code) {
          this.table.dataTable = response.data.data;
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.snackBar(true, error);
      }
      this.table.loading = false;
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
    },
    paginateChange(page, limit) {
      this.table.page = page;
      this.table.limit = limit;
      this.getListConseling();
    },
    unPublish() {
      if (this.$refs.form_bknotes.validate()) {
        this.form_notes.status = "unpublish";
        this.form_notes.loadingUnpublish = true;
        this.saveConseling();
      }
    },
    publish() {
      if (this.$refs.form_bknotes.validate()) {
        this.form_notes.status = "publish";
        this.form_notes.loadingPublish = true;
        this.saveConseling();
      }
    },
    closeDialog() {
      this.modaladdnotes = false;
      this.resetForm();
    },
    resetForm() {
      this.modelImage = null;
      this.form_notes = {
        loading: false,
        loadingPublish: false,
        loadingUnpublish: false,
        person: 0,
        conseling: 0,
        date: "",
        file: "",
        description: "",
        status: "",
        action: ""
      };

      this.$refs.form_bknotes.resetValidation();
    },
    viewBkNotes(person) {
      this.$router.push({
        path: "bk-detail",
        query: {
          person: person,
          year: this.idSchoolYear,
          grade: this.idGrade || this.$route.query.grade,
          class: this.idClass
        }
      });
    }
  }
};
</script>
